import {
  OutboundReferralClinician,
  OutboundReferralExternalProvider,
  OutboundReferralMember,
  OutboundReferralNote,
} from "../../../../features/outbound-referrals/types";
import { Clinician } from "../types";

export enum TriggerSeverityLevels {
  EXTREME = 4,
  HIGH = 3,
  AVERAGE = 2,
  LOW = 1,
}

export enum ExposureDistressLevels {
  HIGHEST_DISTRESS = 10,
  EXTREMELY_DISTRESSED = 9,
  VERY_DISTRESSED = 8,
  QUITE_ANXIOUS_AND_DISTRESSED = 7,
  MODERATE_TO_STRONG_DISTRESS = 6,
  MODERATE_DISTRESS = 5,
  MILD_TO_MODERATE_DISTRESS = 4,
  MILD_DISTRESS = 3,
  MINIMAL_DISTRESS = 2,
  ALERT_AND_AWAKE = 1,
}

export enum ExerciseUnits {
  SUDS = "suds",
  REPS = "rep",
  DURATION = "duration",
}

export interface Member {
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  fears_and_responses_enabled: boolean;
  clinician_id: number;
}

export interface DiagnosisDocumentData {
  member: {
    name: string;
  };
  provider: {
    name: string;
    license: string;
    email: string;
  };
  date: number;
  diagnoses: string[];
}

export interface MemberAssessmentResults {
  dass: {
    form_scores_data: {
      dass_anxiety_score?: number;
      dass_depression_score?: number;
      dass_stress_score?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  docs: {
    form_scores_data: {
      docs_severity?: number;
      docs_symmetry_score?: number;
      docs_responsibility_score?: number;
      docs_contamination_score?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  hrs: {
    form_scores_data: { score?: number };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  qles: {
    form_scores_data: {
      raw_score?: number;
      qles_score?: number;
      number_q_answered?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  rbfbs: {
    form_scores_data: {
      screen_a_repetitively_pick_skin?: number;
      screen_b_repetitively_pull_hair?: number;
      screen_c_repetitively_bite_nails?: number;
      screen_d_other_bfrb?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  tic: {
    form_scores_data: {
      aggregate_score?: number;
      motor_tics_aggregate_score?: number;
      vocal_tics_aggregate_score?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  whodas: {
    form_scores_data: { whodas_disability_score?: number };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
}

export interface MemberFormAutomationSettings {
  form_automation_active: number;
  form_automation_initialized: number;
  form_automation_default_form_names: string[];
  next_form_automation_create_at: string;
  next_form_automation_create_at_timezone: string;
  next_form_automation_due_at: string;
  next_form_automation_form_names: string[];
}

export interface MemberDischargeNote {
  id: number;
  user_id: number;
  created_at: string;
  discharge_at: string;
  discharge_barriers_and_notes: string;
  discharge_care_and_referrals: string;
  discharge_reason: string;
  adhered_to_treatment_plan: string;
  clinician_email: string;
  is_deleted: number;
}

export interface MemberSafetyPlan {
  id: number;
  user_id: number;
  warning_signs: string;
  coping_strategies: string;
  social_distractions: string;
  people_support: string;
  crisis_help: string;
  safe_environment: string;
  created_at: string;
}

export interface MemberContactNoteRiskAssessment {
  suicidality: string[];
  homicidality: string[];
  self_injury: string[];
  risk_details: string;
  risk_details_history?: string;
  deathwish: string;
  deathwish_text: string;
  death_thoughts: string;
  death_thoughts_text: string;
  death_plan: string;
  death_plan_text: string;
  death_intention: string;
  death_intention_text: string;
  death_details: string;
  death_details_text: string;
  death_preparation: string;
  death_preparation_text: string;
  safety_plan: string;
  safety_plan_text: string;
}

export interface MemberContactNote {
  id: number;
  user_id: number;
  contact_at: string;
  contact_name: string;
  clinician_email: string;
  contacted_party_relationship: string;
  contact_method: string;
  contact_reason: string;
  contact_details: string;
  time_spent_in_mins: number;
  risk_assessment_json: string;
}

export interface ReminderEmailValues {
  id: number;
  recpeints: string[];
  recieved_at: string;
  subject: string;
  status: string;
  message_stream: string;
  tag: string;
}

export enum MemberJournalEntryTypes {
  ACCOMMODATION_TRACKING = "accommodation-tracking",
  ACTIVITY_MONITORING = "activity-monitoring",
  COGNITIVE_RESTRUCTURING = "cognitive-restructuring",
  FREE_FORM_ENTRIES = "free-form-entries",
  TARGET_BEHAVIOR_MONITORING = "target-behavior-monitoring",
  THERAPY_GOALS = "therapy-goals",
  TRAP_TRAC_MONITORING = "trap-trac-monitoring",
  // TODO: Remove this once we run a migration to change them to therapy goals
  FIRST_ENTRY = "first_entry",
  MAKING_TIME_FOR_OCD_TREATMENT = "making-time-for-ocd-treatment",
}

export interface MakingTimeForOCDTreatmentJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.MAKING_TIME_FOR_OCD_TREATMENT;
  content: {
    ocd_affects_daily_life: string;
    cant_do_because_of_ocd: string;
    small_steps: string;
    obstacles_encountered: string;
    obstacles_managed: string;
    signs_of_progress: string;
    how_to_hold_accountable:
      | { name: string; checked: boolean; id: string }[]
      | null;
    therapist_help: string;
    datetime: string;
  };
  assigned: number;
}

export interface TherapyGoalsJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.THERAPY_GOALS;
  content: {
    q1_overview: string;
    q2_if_problems_solved_while_sleeping: string;
    q3_first_goal: string;
    q4_first_goal_description: string;
    q5_second_goal: string;
    q6_second_goal_description: string;
    q7_third_goal: string;
    q8_third_goal_description: string;
  };
  assigned: number;
}

export interface FirstEntryJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.FIRST_ENTRY;
  content: {
    q1_overview: string;
    q2_if_problems_solved_while_sleeping: string;
    q3_first_goal: string;
    q4_first_goal_description: string;
    q5_second_goal: string;
    q6_second_goal_description: string;
    q7_third_goal: string;
    q8_third_goal_description: string;
  };
  assigned: number;
}

export interface FreeFormJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.FREE_FORM_ENTRIES;
  content: {
    text: string;
  };
  assigned: number;
}

export interface CognitiveRestructuringJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.COGNITIVE_RESTRUCTURING;
  content: {
    what_happened: string;
    thoughts_images: string;
    belief_in_thoughts: number | null;
    emotions_felt_then: { name: string; intensity: number | null }[];
    alternative_response: string;
    belief_in_what_happened: number | null;
    emotions_felt_now: { name: string; intensity: number | null }[];
    datetime: string;
  };
  assigned: number;
}

export interface AccommodationTrackingJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.ACCOMMODATION_TRACKING;
  content: {
    what_happened: string;
    response: string;
    changes_after_response: string;
    datetime: string;
  };
  assigned: number;
}

export interface TargetBehaviorMonitoringJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.TARGET_BEHAVIOR_MONITORING;
  content: {
    location: string;
    emotions_body_sensations: string;
    thoughts: string;
    prevention: string;
    outcome: string;
    datetime: string;
  };
  assigned: number;
}

export interface TrapTracMonitoringJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.TRAP_TRAC_MONITORING;
  content: {
    what_happened: string;
    emotions: string;
    response_type: string;
    response_details: string;
    outcome: string;
    datetime: string;
  };
  assigned: number;
}

export interface ActivityMonitoringJournalEntry {
  id: number;
  created_at: string;
  type: MemberJournalEntryTypes.ACTIVITY_MONITORING;
  content: {
    what_happened: string;
    pleasure_rating: number;
    sadness_rating: number;
    difficulty_rating: number;
    datetime: string;
  };
  assigned: number;
}

export type MemberJournalEntry =
  | FreeFormJournalEntry
  | TherapyGoalsJournalEntry
  | CognitiveRestructuringJournalEntry
  | AccommodationTrackingJournalEntry
  | TargetBehaviorMonitoringJournalEntry
  | TrapTracMonitoringJournalEntry
  | ActivityMonitoringJournalEntry
  | FirstEntryJournalEntry
  | MakingTimeForOCDTreatmentJournalEntry;

export interface MemberFormAutomationStatus {
  form_automation_active: number;
}

export interface MemberOverview {
  active_subscription: number;
  consent_name: string;
  diagnostic_timezone: string;
  email: string;
  first_name: string;
  id: number;
  is_test_user: number;
  last_name: string;
  protocol_type: string;
  userid: number;
}

export interface MemberExercise {
  id: string;
  intensityGoal: number;
  trigger: string;
  title: string;
  obsession: string;
  anxiety: string;
  timeSpent: number;
  scheduledDate: string;
}

export interface MemberEpisode {
  endIntensity: number;
  id: string;
  obsession: string;
  created_at: string;
  trigger: string;
  compulsion: string;
  compulsionTime: number;
}

export interface MemberTiming {
  created_at: string;
  email: string;
  elapsed_time: number;
}

export interface PreventionTip {
  id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
  assigned?: boolean;
  tip: string;
}

export interface Exposure {
  id: string;
  user_id: number;
  from_therapist: boolean;
  created_at: string;
  created_by: string | null;
  updated_at: string;
  deleted_at: string;
  distress: number;
  title: string;
  assigned: boolean;
  practice_goal: number;
  practice_type: ExerciseUnits;
  obsession_id: Obsession["id"] | null;
}

export interface Compulsion {
  compulsion: string;
  id: string;
  obsession_id: Obsession["id"];
  user_id: number;
  updated_at: string;
  created_at: string;
}

export interface Consequence {
  consequence: string;
  id: string;
  obsession_id: Obsession["id"];
  user_id: number;
  updated_at: string;
  created_at: string;
}

export interface Trigger {
  id: string;
  obsession_id: string;
  severity: number;
  trigger: string;
  user_id: number;
  updated_at: string;
  created_at: string;
}

export interface Obsession {
  compulsions: Compulsion[];
  consequences: Consequence[];
  id: string;
  obsession: string;
  triggers: Trigger[];
  user_id: number;
  created_at: string;
  updated_at: string;
}

export interface ExerciseSession {
  id: number;
  user_id: number;
  did_resist: number;
  distress_after: number;
  distress_before: number;
  exercise_id: number;
  exposure: Exposure["title"];
  expected_distress: number;
  note: null;
  started_at: string;
  completed_at: string;
  target_quantity: number;
  target_unit: ExerciseUnits;
  spent_time: number;
}

export enum ToolTypes {
  IMAGE = "IMAGE",
  LINK = "LINK",
  LOOPTAPE = "LOOPTAPE",
  SCRIPT = "SCRIPT",
}

export interface MemberScript {
  type: ToolTypes.SCRIPT;
  title: string;
  body: string;
}

export interface MemberLink {
  type: ToolTypes.LINK;
  title: string;
  url: string;
  image_url?: string;
  image_aspect_ratio?: string;
  favicon_url?: string;
}
export interface MemberLooptape {
  type: ToolTypes.LOOPTAPE;
  title: string;
  url: string;
  duration_seconds?: number;
}

export interface MemberImage {
  type: ToolTypes.IMAGE;
  title: string;
  url: string;
  aspect_ratio?: number;
}

export interface MemberTool {
  id: number;
  assigned: boolean | null;
  created_at: string;
  created_by: string | null;
  from_therapist: boolean;
  title: string;
  user_id: number;
  type: ToolTypes;
  data: MemberImage | MemberLink | MemberScript | MemberLooptape;
}

export interface MemberClinician {
  clinician: Clinician;
  reason?: string | null;
  is_primary_provider: boolean;
  can_book_ptsd_sessions: boolean | null;
  created_at: string;
  avatar_url?: string;
  next_session_at?: string;
  last_session_at?: string;
  pending_transfer?: boolean;
  label?: string;
}

export interface LinkedAccountChildInfo {
  user_id?: number;
  full_name?: string;
}

export interface MemberSchedulingOverview {
  user_id: number;
  name: string | null;
  email: string;
  phone: string | null;
  timezone: string | null;
  dob: string | null;
  next_appointment: {
    clinician_email: string;
    starts_at: string;
    type: string;
  } | null;
  clinicians: MemberClinician[];
  has_first_session: boolean;
  has_second_session: boolean;
  can_book_sessions: boolean;
  internal_warning: string | null;
  current_scheduling_frequency: string | null;
  emergency_contact: {
    name: string | null;
    phone: string | null;
    email: string | null;
    relation: string | null;
  } | null;
  responsible_party: {
    name: string | null;
    phone: string | null;
    email: string | null;
    relation: string | null;
  } | null;
  physical_address: {
    address: string | null;
    city: string | null;
    state: string | null;
    zip_code: string | null;
    country_code: string | null;
    country_other: string | null;
  };
  zoom_link?: string | null;
  member_age?: number | null;
  special_conditions?: string[] | null;
  bulk_scheduling_guidance?: string | null;
  conqueror_status?: string | null;
  primary_diagnosis?: string | null;
  is_birthday?: boolean | null;
  avatar_url?: string | null;
  linked_account_parent_name?: string | null;
  linked_account_parent_id?: number | null;
  secondary_conditions?: string[] | null;
  member_coverage?: string | null;
  linked_account_child_info?: LinkedAccountChildInfo[] | null;
}

export enum HLOCStatus {
  REFERRED_TO_HLOC = "Referred to HLOC",
  STEPPING_DOWN_FROM_HLOC = "Stepping down from HLOC",
  NO_LONGER_APPLICABLE = "No Longer Applicable",
}
export interface MemberHigherLevelOfCareNote {
  id?: number;
  user_id: number;
  clinician_email: string;
  note_date: string;
  note_reason: HLOCStatus;
  note_details: string;
  is_deleted?: number;
  created_at?: string;
  updated_at?: string;
}

export interface MemberContacts {
  id: number;
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  email: string | null;
  contact_type: string | null;
  is_deleted?: number;
  created_at?: string;
  updated_at?: string;
}

export interface MemberMedication {
  id: number;
  user_id: number;
  medication_name: string;
  dosage: number | null;
  units: string | null;
  frequency: string | null;
  is_current_medication: number;
  created_at: string;
  created_by: string;
  updated_at: string | null;
  updated_by: string | null;
  is_deleted: number;
}

export interface MemberWithForms {
  id: number;
  user_id: number;
  min_due_at: string;
}

export interface MemberReferral {
  id?: number;
  user_id: number;
  referral_date: string;
  referral_provider: string;
  clinician_signed_email: string;
  is_deleted?: number;
  created_at?: string;
  updated_at?: string;
  external_providers_id?: number | null;
  level_of_care?: string | null;
  problem_area?: string | null;
  request_notes?: string | null;
  status?: string | null;
  needs_treatment_for_psychotic_disorder?: number | null;
  admitted_to_hospital_for_psychiatric_reason?: number | null;
  is_actively_suicidal?: number | null;
  has_medicaid?: number | null;
  provider_first_name?: string | null;
  provider_last_name?: string | null;
  provider_organization_name?: string | null;
  type_of_treatment?: string | null;
  setting_preference?: string | null;
  preferred_times?:
    | {
        id: string;
        day: string;
        timeOfDay: string;
      }[]
    | null;
  is_national_provider_referral?: boolean;
  metro_area?: string | null;
}

export interface MemberReferralWithRelations extends MemberReferral {
  notes: OutboundReferralNote[];
  member: OutboundReferralMember;
  external_provider: OutboundReferralExternalProvider | null;
  clinician: OutboundReferralClinician | null;
}

export enum ReferralProviders {
  TALKIATRY = "Talkiatry",
}

export interface MemberTreatmentMilestoneLetter {
  id?: number;
  user_id: number;
  clinician_email: string;
  where_you_started: string;
  what_changed: string;
  impressed_by: string;
  what_we_learned: string;
  outcome_measures: {
    docs_percent_changed?: number | null;
    dass_percent_changed_depression?: number | null;
    dass_percent_changed_anxiety?: number | null;
    dass_depression_checked?: boolean;
    dass_anxiety_checked?: boolean;
  };
  signed_at: string;
  badge_id?: number;
  badge_name?: string;
  created_at?: string;
  is_deleted?: number;
  updated_at?: string;
  subscore_name?: string;
}

export interface MilestoneLetterStatus {
  should_show_letter: boolean;
  has_singed_letter: boolean;
}

export interface MemberClinicalReviewedSchedulingFrequency {
  user_id: number;
  clinical_reviewed_scheduling_frequency: string;
  clinical_reviewed_scheduling_frequency_reason: string;
  clinical_reviewed_scheduling_frequency_updated_at: string;
  clinical_reviewed_scheduling_frequency_updated_by: string;
}

export interface MemberFullResponseDocsOutcome {
  id: string;
  member_id: number;
  score_full_response_date: string;
  full_response_score_perc: number;
  conquerer_status: string;
}
