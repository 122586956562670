import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import getQueryKeys from "../query-keys";

export interface AdminNote {
  id: number;
  text: string;
  author: string;
  created_at: string;
  can_edit: boolean;
  user_id: number;
  is_pinned: number;
  department: string;
  is_therapist: number | null;
  name?: string | null;
  unavailable_reason?: string | null;
  unavailable_from?: string | null;
  unavailable_to?: string | null;
}

export type AdminNotes = AdminNote[];

const getMemberAdminNotes = (
  userId: number,
  accessToken: string,
  signal: AbortSignal
) =>
  api
    .get<AdminNotes>(`/v1/therapy/members/${userId}/admin_notes`, {
      headers: { Authorization: accessToken },
      signal,
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useMemberAdminNotes = (
  userId: number,
  options: UseQueryOptions<AdminNotes, Error, AdminNotes, string[]> = {}
): UseQueryResult<AdminNotes, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberAdminNotes(userId),
    ({ signal }) => getMemberAdminNotes(userId, accessToken, signal),
    { enabled: !!accessToken && userId != null, ...options }
  );
};
